<template>
  <div class="whistleblowing-main">
    <main class="dg-main">
      <DgPanel>
        <h1>{{ $t(`${translationScope}.heading`) }}</h1>
        <p>{{ $t(`${translationScope}.info`) }}</p>
        <i18n :path="`${translationScope}.call_to_action`" tag="p" v-if="!isLoading">
          <a :href="whistleblowingURL">{{ $t(`${translationScope}.click_here`) }}</a>
        </i18n>
      </DgPanel>
    </main>
  </div>
</template>

<script>
import { Structural } from "@/dgui-customer-components/";

export default {
  name: "WhistleblowingLayout",
  components: {
    DgPanel: Structural.DgPanel,
  },
  data() {
    return {
      isLoading: true,
      translationScope: "dg_suite.whistleblowing.overview",
    };
  },
  computed: {
    whistleblowingURL() {
      // TODO: Will be customer-specific.
      return "https://dataguarddemo.integrityline.com/admin";
    },
  },
  created() {
    this.isLoading = false;
    setTimeout(() => {
      window.location.href = this.whistleblowingURL;
    }, 5000);
  },
};
</script>
